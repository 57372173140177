<template>
    <v-row justify="center">
      <v-dialog v-model="$store.state.showQr" max-width="290">
        <v-card>
          <v-card-title class="headline">IMEI QR</v-card-title>
          <v-card-text>
            <qr-code 
                :text="$store.state.valueQr"
                color="#000"
                bg-color="#fff" 
                error-level="L">
            </qr-code>
            
            ID: {{ $store.state.valueQr }}
            
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="close">Cerrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>
<script>
  export default {
    name: 'qrDialog',	
    methods: {
      close(){
        this.$store.commit('SET_QR',{show_qr: false, value_qr: null}, {root: true});
      }
    }

  }
</script>
